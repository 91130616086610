import React from 'react' 
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 50px;
  margin-bottom: 22px;
`

const PageAMOSection = () => (  
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid

        return (
          <div className='bg-gray-200'>
            <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
              <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>Interpacific Asset Management is AMO certified.</h2>

              <StyledUnderline></StyledUnderline>

              <div className='lg:flex block'>
                <div className='lg:w-2/3 w-full pr-8 sm:pr-0'>

                  <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Management Expertise</h3>

                  <p className='text-xl text-gray-900 mb-6'>To qualify for the AMO designation, a firm must have been actively engaged in real estate management activity for at least three years. This experience, along with the education needed for the designation, means that AMO firms are the most knowledgeable about the unique needs of your property and about the local market. And the three-year reaccredidation requirement ensures that all AMO firms continually satisfy the requirements for membership and thus maintain the high standards of the AMO program.</p>

                  <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Professional Competence</h3>

                  <p className='text-xl text-gray-900 mb-6'>AMO firms are required to have a CERTIFIED PROPERTY MANAGER (CPM) directing and supervising the firm’s real estate management activities. The CPM designation is the most respected real estate management designation. Only individuals who complete extensive course study, have met experience requirements in both fiscal and operations management, and adhere to a professional code of ethics attain the CPM designation. Put this competence to work for your real estate investment.</p>

                  <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Financial Responsibility</h3>

                  <p className='text-xl text-gray-900 mb-6'>Each AMO firm undergoes an independent financial review and must demonstrate financial stability and integrity. IREM also requires AMO firms to maintain insurance coverage to ensure that your investment is protected. You can count on an AMO firm to manage your real estate assets with your investment goals in mind. Call upon an AMO firm to obtain the maximum return on your asset by improving cash flow and enhancing its value.</p>

                  <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Integrity and Ethics</h3>

                  <p className='text-xl text-gray-900 mb-6'>Adherence to the AMO Code of Ethics is a requirement for the designation and is strictly enforced by IREM. The code regulates the firm’s relationship with you as an owner/investor and requires the AMO to use the utmost care in promotion, advertising, and operation procedures. You can trust your investment to an AMO.</p>

                  <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Profile of an AMO Firm</h3>

                  <p className='text-xl text-gray-900 mb-4'>AMO firms have served the real estate management community since 1946.</p>

                  <p className='text-xl text-gray-900 mb-4'>As of 1997, AMO firms are responsible for managing 1.5 million residential units and almost 600 million square footage of commercial property with an estimated combined value of over $88 billion.</p>

                  <p className='text-xl text-gray-900 mb-4'>AMO firms manage almost every type of property: office buildings, retail properties and shopping centers, apartments, federally-assisted housing, condominiums, single family homes, and industrial properties. Many firms can also provide you with additional real estate management services. Whatever the size or type of your real estate investment, there is an AMO firm prepared to manage it for you.</p>

                  <p className='text-xl text-gray-900 mb-4'>Why not let the best in real estate management transform their knowledge into value for your real estate assets? With an AMO firm, you’re no only getting a service, you’re getting a partner.</p>
                </div>

                <div className='flex flex-col items-center w-1/3 sm:w-full p-6 sm:p-0'>
                  <Img fluid={imageDataOne} alt='AMO logo' className='w-full' />
                </div>

              </div>
            </div>
          </div>      
        )
      }
    }
  />      
)

export default PageAMOSection

const query = graphql`
  query PageAMOSectionQuery {
    imageOne: file(relativePath: { eq: "logos/amo-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`