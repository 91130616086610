import React from "react"
import PageContainer from "../containers/PageContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import PageAMOSection from "../sections/PageAMOSection"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"

export default () => (
  <PageContainer>
    <HeadData 
      title='AMO Management' 
      description='Interpacific Asset Management is AMO certified. To qualify for the AMO designation, a firm must have been actively engaged in real estate management activity for at least three years.'
    />

    <PageHeaderImageBg>
      <HeaderNav />
      <PageHeaderText
        title='AMO Management'
      />
    </PageHeaderImageBg>

    <PageAMOSection />

    <HomeAffiliationSection />

    <CTASection />
  </PageContainer>
)
